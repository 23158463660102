// src/pages/ProductDetailsPage.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, TextField, CircularProgress, Grid, Switch, InputLabel, MenuItem, Select, FormControl, Chip, Alert } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useParams, useNavigate } from 'react-router-dom';
import { Product, ProductImage } from '../types';
import { mediaApiKey, mediaUrl, productsApiKey, productsUrl } from '../services/constants';

const ProductDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || '{}'));
  const [newImage, setNewImage] = useState<File | null>(null);
  const navigate = useNavigate();

  // Editable fields state
  const [editFields, setEditFields] = useState({
    name: '',
    description: '',
    tags: '',
    unitPrice: 0,
    unitType: '',
    minimumStockThreshold: 0,
    unitVolume: 0,
    compositionFormulaPercentage: 0,
    productFormula: '',
    isAvailable: false,
    categories: [] as string[],
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${productsUrl}/api/v1/products/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "x-api-key": productsApiKey,
          },
        });
        const productData = response.data;
        setProduct(productData);
        setEditFields({
          name: productData.name,
          description: productData.description,
          tags: productData.tags,
          unitPrice: productData.unitPrice,
          unitType: productData.unitType,
          minimumStockThreshold: productData.minimumStockThreshold,
          unitVolume: productData.unitVolume,
          compositionFormulaPercentage: productData.compositionFormulaPercentage,
          productFormula: productData.productFormula,
          isAvailable: productData.isAvailable,
          categories: productData.categories,
        });
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleFieldChange = (field: string, value: any) => {
    setError(null);
    setInfo(null);
    setEditFields((prev) => ({ ...prev, [field]: value }));
  };

  const extractFileName = (path: string): string => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  const handleAddImage = async () => {
    if (!newImage || !product) return;
    setError(null);
    setInfo(null);
    const formData = new FormData();
    formData.append("File", newImage);
    formData.append("Id", product.id);
    try {
      const mediaResponse = await axios.post(`${mediaUrl}/api/v1/media`, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "x-api-key": mediaApiKey,
        },
      });
      if (mediaResponse.status === 201) {
        const imageData = {
          id: mediaResponse.data.id,
          filename: mediaResponse.data.path,
        };
        const productResponse = await axios.post(
          `${productsUrl}/api/v1/products/${id}/images`,
          imageData,
          {
            headers: {
                Authorization: `Bearer ${user.token}`,
                "x-api-key": productsApiKey,
            },
          }
        );
        setProduct({ ...product, productImages: [...product.productImages, productResponse.data.productImages] });
        setNewImage(null);
        setInfo('Image added successfully!');
      }
    } catch (error) {
      console.error('Error adding image:', error);
      setError('Failed to add image.');
    }
  };


  const handleRemoveImage = async (id: string, image:ProductImage) => {
    setError(null);
    setInfo(null);
    const fileName = extractFileName(image.fileName);
    try {
      const mediaResponse = await axios.delete(`${mediaUrl}/api/v1/media?filename=${fileName}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "x-api-key": mediaApiKey,
        },
      });
      if (mediaResponse.status === 200) {
        const imageData = {
            id: image.id,
            filename: image.fileName,
          };
          const productResponse = await axios.delete(`${productsUrl}/api/v1/products/${id}/images`, {
            data: imageData,
            headers: {
                Authorization: `Bearer ${user.token}`,
                "x-api-key": productsApiKey,
              },
          });
        if (product && productResponse.status === 200) {
          setProduct({ 
            ...product, 
            productImages: product.productImages.filter((img) => img.id !== image.id),
            categories: product.categories || []
          });
          setInfo('Image removed successfully!');
        }
    };
    } catch (error) {
      console.error('Error removing image:', error);
      setError('Failed to remove image.');
    }
  };

  const handleSaveChanges = async () => {
    try {
      await axios.patch(`${productsUrl}/api/v1/products/${id}`, editFields, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "x-api-key": productsApiKey,
        },
      });
      setInfo('Product updated successfully!');
    } catch (error) {
      console.error('Error updating product:', error);
      setError('Failed to update product.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return <Typography variant="h6">Product not found</Typography>;
  }

  return (
    <>
        {error && <Alert severity="error">{error}</Alert>}
        {info && <Alert severity="info">{info}</Alert>}
        <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>{editFields.name}</Typography>

        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
            {product.productImages.length > 0 ? (
                <Carousel>
                {product.productImages.map((image) => (
                    <Box key={image.id} position="relative">
                    <img src={image.fileName} alt={product.name} width="100%" height="auto" />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveImage(product.id, image)}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        Remove
                    </Button>
                    </Box>
                ))}
                </Carousel>
            ) : (
                <Typography variant="body1" color="textSecondary" textAlign="center" p={10}>
                No images available for this product
                </Typography>
            )}
            {/* Image Upload Section */}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Add New Image
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                    type="file"
                    onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setNewImage(target.files ? target.files[0] : null);
                    }}
                    sx={{ mr: 2 }} // Adds margin to the right of the TextField for spacing
                    />
                    <Button variant="contained" color="primary" onClick={handleAddImage}>
                    Add Image
                    </Button>
                </Box>
            </Box>
            </Grid>

            <Grid item xs={12} md={6}>
            <Box>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Typography>Available</Typography>
                <Switch
                    checked={editFields.isAvailable}
                    onChange={(e) => handleFieldChange('isAvailable', e.target.checked)}
                    color="primary"
                />
                </Box>
                <TextField
                  label="Name"
                  fullWidth
                  value={editFields.name}
                  onChange={(e) => handleFieldChange('name', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  value={editFields.description}
                  onChange={(e) => handleFieldChange('description', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Tags"
                  fullWidth
                  value={editFields.tags}
                  onChange={(e) => handleFieldChange('tags', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Unit Price"
                  type="number"
                  fullWidth
                  value={editFields.unitPrice}
                  onChange={(e) => handleFieldChange('unitPrice', parseFloat(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Unit Type"
                  fullWidth
                  value={editFields.unitType}
                  onChange={(e) => handleFieldChange('unitType', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Unit Volume"
                  type="number"
                  fullWidth
                  value={editFields.unitVolume}
                  onChange={(e) => handleFieldChange('unitVolume', parseFloat(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Minimum Stock Threshold"
                  type="number"
                  fullWidth
                  value={editFields.minimumStockThreshold}
                  onChange={(e) => handleFieldChange('minimumStockThreshold', parseFloat(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Composition Formula Percentage"
                  type="number"
                  fullWidth
                  value={editFields.compositionFormulaPercentage}
                  onChange={(e) => handleFieldChange('compositionFormulaPercentage', parseFloat(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Product Formula"
                  fullWidth
                  multiline
                  rows={2}
                  value={editFields.productFormula}
                  onChange={(e) => handleFieldChange('productFormula', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Categories</InputLabel>
                <Select
                    multiple
                    value={editFields.categories}
                    onChange={(e) => handleFieldChange('categories', e.target.value as string[])}
                    renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                        <Chip key={value} label={value} />
                        ))}
                    </Box>
                    )}
                >
                    {/* Sample category options */}
                    {['Category1', 'Category2', 'Category3'].map((category) => (
                    <MenuItem key={category} value={category}>
                        {category}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                Save Changes
                </Button>
            </Box>
            </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, width: '100%' }}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
            Back to Products
            </Button>
        </Box>
        </Box>
    </>
  );
};

export default ProductDetailsPage;